/***********************************************************************************************************************
 ** Theme
 ** NOTE: Import theme first to allow for overriding of !default values
 **********************************************************************************************************************/
@import 'theme';


/***********************************************************************************************************************
 ** Colours
 **********************************************************************************************************************/
$reliable-blue: #203b4c;
$reliable-blue-75: #586c79;
$reliable-blue-70: #637682;
$reliable-blue-50: #c4c4c4;
$reliable-blue-25: #c7ced2;
$reliable-blue-20: #c8ced1;
$reliable-blue-10: #e9ebed;
$reliable-blue-5: #f4f5f6;

$occasional-orange: #ff8150;

$dusty-yellow: #f8df8d;
$dusty-yellow-75: #f7e49f;

$baby-blue: #84d2e2;

$background-page-grey: #fbfbfb;

$lp-cream: #fefcf5;

$category-animals: #B9ECFD;
$category-community: #FDE580;
$category-culture: #F8AD6D;
$category-education: #C8FCD2;
$category-environment: #7DE7B5;
$category-health: #FCB9C5;
$category-humanity: #F87982;
$category-international: #A5A9EF;
$category-spiritual: #6E7191;


/***********************************************************************************************************************
 ** Typography
 **********************************************************************************************************************/
$font: 'DM Sans' !default;
$font-stack: $font, sans-serif !default;
// Necessary for the <b> and <strong> tags, which depend on the bootstrap $font-bolder variable,
// as the body font we use doesn't have the weight that bootstrap specifies by default.
$font-weight-bolder: 700 !default;
$headings-font-weight: 700 !default;
$header-font: 'DM Sans' !default;
$header-font-stack: $header-font, $font, sans-serif !default;

$font-weight-normal: 400;

$font-size-xl: 2.66667rem;
$font-size-lg: 1.86667rem;
$font-size-md: 1.33333rem;
$font-size-sm: 1.06667rem;
$font-size-xs: 0.8rem;

// Line heights should be used with * 1rem
$line-height-xl: 3.2;
$line-height-lg: 2.26667;
$line-height-md: 1.73333;
$line-height-sm: 1.33333;
$line-height-xs: 1;


/***********************************************************************************************************************
 ** Sizes
 **********************************************************************************************************************/
$baseline-px: 15px; // this is the value that will be used for 1rem in the root html element
$nav-bar-height: 65px;
$nav-bar-image-height: 40px;
$nav-bar-image-height-mobile: 40px;
$hero-image-ratio: 66%; // this is the padding-top that should be used for charity and campaign images


/***********************************************************************************************************************
 ** Breakpoints
 **********************************************************************************************************************/
$screen-size-xl: 1200px;
$screen-size-lg: 992px;
$screen-size-md: 768px;
$screen-size-sm: 576px;


/***********************************************************************************************************************
 ** Form items
 **********************************************************************************************************************/
$form-item-line-height: $line-height-sm * 1rem;
$form-item-vertical-padding: 5px;
$form-item-left-padding: 4px;




/***********************************************************************************************************************
 ** Z-index
 ** NOTE: Variable values can't be assigned in SCSS loops so variables need to be explicitly set
 **********************************************************************************************************************/
$z-index-step: 10;
$z-index-intercom: 2147483001;
$z-index-toastr: 2147482961;
$z-index-fullscreen-loader: $z-index-intercom - $z-index-step * 1;
$z-index-fullscreen-toastr: $z-index-intercom - $z-index-step * 2;
$z-index-modal-popup: $z-index-intercom - $z-index-step * 3;
$z-index-nav-bar: $z-index-intercom - $z-index-step * 4;
$z-index-explore-filter-overlay: $z-index-intercom - $z-index-step * 5;
$z-index-my-impact-fullscreen-map: $z-index-intercom - $z-index-step * 6;




/***********************************************************************************************************************
 ** CSS variables
 **********************************************************************************************************************/
:root {
    --lp-cream: #{$lp-cream};
}




/***********************************************************************************************************************
 ** Bootstrap
 ** Only import functions and variables so build size won't be effected after compilation
 **********************************************************************************************************************/
@import 'partials/vendors/bootstrap';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
