$spacing: 10px;

.toast-container {
    max-width: 345px;
    width: calc(100% - #{$spacing} * 2);
    z-index: $z-index-fullscreen-toastr;
}

.toast-bottom-left {
    bottom: $spacing;
    left: $spacing;
}

.ngx-toastr {
    max-width: 1110px;
}
