/*
 * _rem-to-px.scss
 * Little Phil
 *
 * Created on 8/10/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

/**
 * Converts a REM value to a rounded whole pixel value
 * @param {string} $rem - REM value to convert
 */
@function rem-to-px($rem) {
    @if $rem == 0 {
        @return 0;
    }
    @return round(calc($rem / 1rem) * $baseline-px)
}
