/*!
 * _gaps.scss
 * Little Phil
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/gap
 *
 * Created on 11/11/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

@each $index, $rems in $spacers {

    .gap-#{$index} {
        gap: rem-to-px($rems) !important;
    }

}
