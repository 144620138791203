/*!
 * _border.scss
 * Little Phil
 *
 * Created on 23/3/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

.border-primary {
    --bs-border-opacity: 1;
    --bs-border-color: #{$reliable-blue};
}

.border-primary-50 {
    --bs-border-opacity: 1;
    --bs-border-color: #{$reliable-blue-50};
}

.border-primary-20 {
    --bs-border-opacity: 1;
    --bs-border-color: #{$reliable-blue-20};
}

.border-primary-10 {
    --bs-border-opacity: 1;
    --bs-border-color: #{$reliable-blue-10};
}

.text-primary-50 {
    color: $reliable-blue-50;
}
