@import '../variables';

/***********************************************************************************************************************
 ** Standard HTML elements
 **********************************************************************************************************************/
h1, h2, h3, h4, h5, h6 {
    text-rendering: geometricPrecision; // Improves rendering of fonts when scaling

    span {
        // inherit font properties from header element
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
}

body, button, input, span, a, textarea, li {
    font-family: $font-stack;
    font-size: rem-to-px($font-size-sm);
    line-height: rem-to-px($line-height-sm * 1rem);
    font-weight: $font-weight-normal;
    text-rendering: geometricPrecision; // Improves rendering of fonts when scaling
}

pre {
    color: $body-color;
    font-family: $font-stack;
    line-height: rem-to-px($line-height-sm * 1rem);
    white-space: pre-wrap;
}

h1 {
    font-family: $header-font-stack;
    font-size: rem-to-px($font-size-xl);
    font-weight: $font-weight-bolder;
    line-height: rem-to-px($line-height-xl * 1rem);
    min-height: rem-to-px($line-height-xl * 1rem);
    -webkit-font-smoothing: antialiased;
}

h2 {
    font-family: $header-font-stack;
    font-size: rem-to-px($font-size-lg);
    line-height: rem-to-px($line-height-lg * 1rem);
    letter-spacing: -0.5px;
    min-height: rem-to-px($line-height-lg * 1rem);
    -webkit-font-smoothing: antialiased;
}

h3 {
    font-family: $header-font-stack;
    font-size: rem-to-px($font-size-md);
    line-height: rem-to-px($line-height-md * 1rem);
    letter-spacing: -0.25px;
    min-height: rem-to-px($line-height-md * 1rem);
    -webkit-font-smoothing: antialiased;
}

h4 {
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
}

p {
    line-height: rem-to-px($line-height-sm * 1rem);
    min-height: rem-to-px($line-height-sm * 1rem);
}


/***********************************************************************************************************************
 ** Font size classes
 **********************************************************************************************************************/
.font-size-xl {
    font-size: rem-to-px($font-size-xl);
    line-height: rem-to-px($line-height-xl * 1rem);
    min-height: rem-to-px($line-height-xl * 1rem);
}

.font-size-lg {
    font-size: rem-to-px($font-size-lg);
    line-height: rem-to-px($line-height-lg * 1rem);
    min-height: rem-to-px($line-height-lg * 1rem);
}

.font-size-md {
    font-size: rem-to-px($font-size-md);
    line-height: rem-to-px($line-height-md * 1rem);
    min-height: rem-to-px($line-height-md * 1rem);
}

.font-size-sm {
    font-size: rem-to-px($font-size-sm);
    line-height: rem-to-px($line-height-sm * 1rem);
    min-height: rem-to-px($line-height-sm * 1rem);
}

.font-size-xs {
    font-size: rem-to-px($font-size-xs);
    line-height: rem-to-px($line-height-xs * 1rem);
    min-height: rem-to-px($line-height-xs * 1rem);
}


/***********************************************************************************************************************
 ** Font family class
 ** These classes allow the usages of the specific fonts on any element
 **********************************************************************************************************************/
.header-font {
    font-family: $header-font-stack;
    font-weight: unset; // use the default font weight
}

.body-font {
    font-family: $font-stack;
}


/***********************************************************************************************************************
 ** Min line height class
 ** This class will keep an element's min-height at its line height
 ** This class should be used to stop the UI from content jumping during async request
 ** https://stackoverflow.com/a/33298969/6284106
 **********************************************************************************************************************/
.min-line-height::after {
    content: '\200B';
    visibility: hidden;
}
