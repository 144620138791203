@import '../variables';

.shimmer {
    animation: shimmer 1s infinite;
    animation-delay: -1s;
    -webkit-mask: linear-gradient(-90deg, #000 30%, #0005, #000 70%) right/300% 100%;
}

@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}

.empty-list {
    .cause-image, label, img, h1, h2, h3, p, span {
        background-color: $reliable-blue-5 !important;
        color: transparent; // hide text
        @extend .shimmer;
    }
}
