/*
 * _order.scss
 * Little Phil
 *
 * Created on 3/11/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

// Loop over sizes and create classes that can be used to order flex elements
@each $breakpoint, $breakpoint-px in $grid-breakpoints {

    // Create order classes form 0 to 10 eg. order-lg-3
    @for $i from 0 through 10 {

        @include media-breakpoint-up($breakpoint) {
            .order-#{$breakpoint}-#{$i} {
                order: $i;
            }
        }

    }
}
