@import '../theme';

@mixin radio-button {
    border-radius: 12px;
    display: inline-block;
    height: 24px;
    width: 24px;
}

.radio-button-on {
    border: 9px solid $secondary;
    @include radio-button();
}

.radio-button-off {
    border: 1px solid $primary;
    @include radio-button();
}
