/***********************************************************************************************************************
 ** Colours
 **********************************************************************************************************************/
$success: #4cd964;
$danger: #ff3b30;
$light: $reliable-blue-25;
$input-border-color: $reliable-blue-50;
$input-placeholder-color: $reliable-blue-50; // NOTE: This colour is also used in the Stripe card fields
$table-group-separator-color: transparent; // remove borders between thead, tbody and tfoot

$min-contrast-ratio: 2; // Ratio for determining if black or white text should be used on a certain background color




/***********************************************************************************************************************
 ** Cards
 **********************************************************************************************************************/
$card-spacer-x: 1rem;




/***********************************************************************************************************************
 ** Inputs
 **********************************************************************************************************************/
$input-font-size: 1.066rem;




/***********************************************************************************************************************
 ** Breakpoints
 ** Note: override breakpoints and container sizes to remove the xxl size as site does not scale well with it
 **********************************************************************************************************************/
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1180px
);




/***********************************************************************************************************************
 ** Spacing
 **********************************************************************************************************************/
$grid-gutter-width: 2rem; // 30px
$container-padding-x: 2rem; // 30px (bootstrap divides the container padding by divided 🤷)

$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * 0.33333), // 5px
    2: ($spacer * 0.66667), // 10px
    3: $spacer, // 15px
    4: ($spacer * 1.33333), // 20px
    5: ($spacer * 1.66667), // 25px
    6: ($spacer * 2), // 30px
    7: ($spacer * 3.33333), // 50px
    8: ($spacer * 6.66667), // 100px
    9: ($spacer * 10) // 150px
);

$headings-margin-bottom: 0; // Remove margin bottom from h1-h6
$paragraph-margin-bottom: 0; // Remove margin bottom from p




/***********************************************************************************************************************
 ** Shadows
 **********************************************************************************************************************/
$box-shadow-sm: 0 0 5px rgba(0, 0, 0, 0.05);
$box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
$box-shadow-lg: 0 0 5px rgba(0, 0, 0, 0.15);




/***********************************************************************************************************************
 ** Dropdown
 **********************************************************************************************************************/
$dropdown-border-radius: 0;

$dropdown-link-hover-color: $reliable-blue;
$dropdown-link-hover-bg: $reliable-blue-5;

$dropdown-link-active-color: $reliable-blue;
$dropdown-link-active-bg: $reliable-blue-10;




/***********************************************************************************************************************
 ** Modal
 **********************************************************************************************************************/
$modal-content-border-radius: 0;




/***********************************************************************************************************************
 ** Borders
 **********************************************************************************************************************/
$border-radius: 10px;
$border-radius-sm: 5px;
$border-radius-lg: 20px;

$border-color: $reliable-blue-10;
$hr-border-color: $reliable-blue-10;



/***********************************************************************************************************************
 ** Buttons
 **********************************************************************************************************************/
$btn-border-radius-lg: 50rem;
$btn-font-size-lg: $font-size-sm;
$btn-padding-y-lg: 15px;
$btn-padding-x-lg: 24px;

$btn-border-radius: 50rem;
$btn-font-size: $font-size-sm;
$btn-padding-y: 15px;
$btn-padding-x: 24px;

$btn-border-radius-sm: 50rem;
$btn-font-size-sm: $font-size-xs;
$btn-padding-y-sm: 12.5px;
$btn-padding-x-sm: 20px;




/***********************************************************************************************************************
 ** Code
 **********************************************************************************************************************/
$code-font-size: inital; // Set back to default size to allow for pre elements to be used for text blocks




/***********************************************************************************************************************
 ** Links
 **********************************************************************************************************************/
$link-decoration: none;
$link-hover-decoration: underline;




/***********************************************************************************************************************
 ** HR
 **********************************************************************************************************************/
$hr-color: $reliable-blue-10;
$hr-opacity: 1;




/***********************************************************************************************************************
 ** Forms
 **********************************************************************************************************************/
$form-floating-padding-y: 0;
$form-floating-padding-x: 0;
$form-floating-label-opacity: 1;
$form-floating-label-transform: scale(0.75) translateY(-1.5rem);
$form-floating-input-padding-t: initial; // allow element to specify it's own top padding
$form-floating-input-padding-b: initial; // allow element to specify it's own bottom padding




/***********************************************************************************************************************
 ** Navbar
 **********************************************************************************************************************/
$navbar-nav-link-padding-x: 0;




/***********************************************************************************************************************
 ** Scroll
 **********************************************************************************************************************/
$enable-smooth-scroll: false;
