/**
 * Generic styles that are app wide
 */
@import '../theme';
@import '../variables';

html {
    font-size: $baseline-px; // Fonts and Bootstrap goes off rem which is the font-size of the root element
}

input {
    border-radius: 0; /* Safari iOS */
}

img {
    max-width: 100%;
}

pre {
    color: $body-color;
    overflow-y: hidden; // bootstrap sets overflow: auto and it causes a small 1px wiggle wiggle
}

a, button:not(disabled) {
    cursor: pointer;
}

.white-page-bg {
    background-color: white;
    min-height: 100vh;
}

.grey-page-bg {
    background-color: $background-page-grey;
}

.grey-page-bg-md {
    min-height: calc(100vh);

    @include media-breakpoint-up('md') {
        background-color: $background-page-grey;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.required-star {
    color: $danger;
}

.horizontal-flip {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}

.text-decoration-underline {
    text-decoration: underline;
}

.bg-primary-20 {
    background-color: $reliable-blue-20;
}

.bg-primary-10 {
    background-color: $reliable-blue-10;
}

.bg-primary-5 {
    background-color: $reliable-blue-5;
}
