@tailwind base;
@tailwind components;
@tailwind utilities;

// FRAMEWORKS
@import 'node_modules/@little-phil/frontend/scss/variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'node_modules/ngx-toastr/toastr';

// LITTLE PHIL
@import 'node_modules/@little-phil/frontend/scss/main';
@import 'node_modules/@little-phil/frontend/scss/partials/vendors/bootstrap-override';
@import 'node_modules/@little-phil/frontend/scss/partials/vendors/bootstrap-datepicker';
@import 'node_modules/@little-phil/frontend/scss/partials/vendors/toastr';

// IMPORT CONFIGS
@import 'partials/mixins';

// PARTIALS
@import 'partials/override';
@import 'partials/base';
@import 'partials/buttons';
@import 'partials/icons';
@import 'partials/dashboard';
