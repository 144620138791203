/*
 * _width.scss
 * Little Phil
 *
 * Created on 29/10/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

// Create 100% width classes for different breakpoints
@each $breakpoint, $breakpoint-px in $grid-breakpoints {

    @include media-breakpoint-up($breakpoint) {
        .w#{$breakpoint}-100 {
            width: 100%;
        }
    }

    @include media-breakpoint-down($breakpoint) {
        .w#{$breakpoint}-down-100 {
            width: 100%;
        }
    }
}

.min-w-0 {
    min-width: 0; // https://css-tricks.com/flexbox-truncated-text/
}
