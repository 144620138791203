/*
 * _forms.scss
 * Little Phil
 *
 * Created on 23/9/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

@mixin form-item {
    background-color: transparent;
    border: none;
    border-bottom: solid 1px $border-color;
    border-radius: 0;
    box-shadow: none;
    color: $body-color;
    outline: none;
    line-height: $form-item-line-height;
    padding: 0;
    width: 100%;

    &:disabled {
        color: $reliable-blue-25;
        -webkit-text-fill-color: $reliable-blue-25; /* Safari */
    }
}

@mixin form-item-icon {
    position: absolute;
    right: 0;
    bottom: 8px;
}

/**
 * Mixin for input placeholder
 * https://stackoverflow.com/a/27960012
 */
@mixin form-item-placeholder($selector) {
    #{$selector}::-webkit-input-placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }
    #{$selector}::-moz-placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }
    #{$selector}:-ms-input-placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }
    #{$selector}::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }
}

/**
 * Mixin for hide arrow controls for number fields
 * https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
 */
@mixin form-hide-number-controls {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
}
