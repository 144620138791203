//
// Bootstrap override
//
html {
    font-size: 15px; // Bootstrap goes off rem which is the font-size of the root element
}

// Theme variables
$primary: $reliable-blue !default;
$secondary: $occasional-orange !default;
$body-color: $reliable-blue !default;

// Cards
$card-spacer-x: 1rem;

// Threshold for when to use white vs black text
$yiq-contrasted-threshold: 180;

// Input field size
$input-font-size: 1.066rem;

// Change margin/padding steps
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
        (
            0: 0,
            1: ($spacer * 0.33333), // 5px
            2: ($spacer * 0.66667), // 10px
            3: $spacer, // 15px
            4: ($spacer * 1.33333), // 20px
            5: ($spacer * 1.66667), // 25px
            6: ($spacer * 2), // 30px
            7: ($spacer * 3.33333), // 50px
            8: ($spacer * 6.66667), // 100px
            9: ($spacer * 10) // 150px
        ),
        $spacers
);

// Shadows
$box-shadow-sm: 0 0 5px rgba(0, 0, 0, 0.05);
$box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
$box-shadow-lg: 0 0 5px rgba(0, 0, 0, 0.15);

// Nav bar
$dropdown-border-radius: 0;

// Modal
$modal-content-border-radius: 0;

// Borders
$border-color: $reliable-blue-20;

// Buttons
//$btn-padding-y-lg: $input-btn-padding-y-lg !default;
//$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $font-size-md;
$btn-line-height-lg: $line-height-md * 1rem;

//$btn-padding-y: $input-btn-padding-y !default;
//$btn-padding-x: $input-btn-padding-x !default;
$btn-font-size: $font-size-sm;
$btn-line-height: $line-height-sm * 1rem;

//$btn-padding-y-sm: $input-btn-padding-y-sm !default;
//$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $font-size-sm;
$btn-line-height-sm: $line-height-sm * 1rem;
