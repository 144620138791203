@import 'vendors/bootstrap';
@import 'node_modules/@little-phil/frontend/scss/variables';

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: $input-placeholder-color;
}

.ng-select .ng-select-container {
    border-radius: $border-radius;
}

.form-control + .form-control-feedback,
.ng-select + .form-control-feedback {
    color: red;
    position: initial;
    margin-top: initial;

    p {
        font-size: 14px;
    }
}

.form-control.rounded-pill {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.has-danger .form-control {
    border: 1px solid $danger;
}

.has-success .form-control {
    border: 1px solid $success;
}

.dropdown-menu {
    margin: 0;
}

#captcha-form > div {
    margin: 0 auto;
}
