/***********************************************************************************************************************
 ** Key frames
 **********************************************************************************************************************/
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}




/***********************************************************************************************************************
 ** Transitions
 **********************************************************************************************************************/
$duration: 300ms;

.bg-transition {
    transition: background-color $duration;
}

.border-transition {
    transition: border 50ms;
}

.width-transition {
    transition: width $duration;
}




/***********************************************************************************************************************
 ** Animations
 **********************************************************************************************************************/
.icon-spin {
    $duration: 2s;
    -webkit-animation: spin $duration linear infinite;
    -moz-animation: spin $duration linear infinite;
    animation: spin $duration linear infinite;
}




/***********************************************************************************************************************
 ** Rotate
 **********************************************************************************************************************/
.rotate {
    transition: transform 350ms;
}

.rotate-45 {
    transform: rotate(45deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}
