/*
 * _shadows.scss
 * Little Phil
 *
 * Created on 12/10/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

// Loop over sizes and create a class that removes the box-shadow at that different sizes
@each $breakpoint, $breakpoint-px in $grid-breakpoints {
    @include media-breakpoint-down($breakpoint) {
        .shadow-none-#{$breakpoint}-down {
            box-shadow: none !important;
        }

        .shadow-#{$breakpoint}-down {
            box-shadow: $box-shadow !important;
        }
    }

    @include media-breakpoint-up($breakpoint) {
        .shadow-none-#{$breakpoint}-up {
            box-shadow: none !important;
        }

        .shadow-#{$breakpoint}-up {
            box-shadow: $box-shadow !important;
        }
    }
}
