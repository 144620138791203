@import 'node_modules/@little-phil/frontend/scss/variables';

.btn-outline-baby-blue {
    background: transparent none;
    border-color: $baby-blue;
    color: $reliable-blue;

    &:hover, &:active, &:focus {
        background: $baby-blue;
        border-color: $baby-blue;
        color: white;
    }
}

.pagination-btn {
    width: 160px;
    height: 60px;
    outline: none;
}

