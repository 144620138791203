/**
 * Generic styles that are app wide
 */

img.ng-lazyloaded.fadein {
    animation: fadein 1s;
}

// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.grecaptcha-badge {
    visibility: hidden;
}
