/*
 * _forms.scss
 * Little Phil
 *
 * Created on 8/10/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

/**
 * Returns the calc for the height of the text area with the provided number of lines
 * @param {number} [$lines] - The desired number of lines displayed
 */
@function form-text-area-min-height($lines: 5) {
    $vertical-padding: $form-item-vertical-padding * 2;
    $lines-height: rem-to-px($form-item-line-height * $lines);
    @return $lines-height + $vertical-padding;
}
