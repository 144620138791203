/**
 * Sets the correct image and height for the provided brand
 */
@mixin payment-method-icon($image-name, $height) {
    background-image: url('/assets/img/giving-gateway/#{$image-name}.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height: $height * 1px;
}

/**
 * Map of supported card brands
 */
$payment-icons: (
    american-express: (height: 12, width: 43),
    apple-pay: (height: 20, width: 47),
    google-pay: (height: 20, width: 51),
    jcb: (height: 15, width: 20),
    mastercard: (height: 12, width: 19),
    microsoft-pay: (height: 20, width: 48),
    visa: (height: 10, width: 27),
);

/**
 * Loop over brands and create variable width and fixed width icons
 */
@each $brand, $sizes in $payment-icons {
    $height: map-get($sizes, 'height');
    $width: map-get($sizes, 'width');

    .#{$brand} {
        @include payment-method-icon($brand, $height);
        width: $width * 1px;
    }

    .#{$brand}-fixed {
        @include payment-method-icon($brand, $height);
        width: 35px;
    }
}
