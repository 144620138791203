/*
 * _buttons.scss
 * Little Phil
 *
 * Created on 23/11/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

.btn-white {
    color: $primary;
    background-color: #ffffff;
    border-color: #ffffff;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05)
}

.btn-white:hover {
    background-color: $reliable-blue-5;
    border-color: $reliable-blue-5;
}
