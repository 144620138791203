// Note: These should not be used anymore as Bootstrap already provide responsive breakpoints
//  (See https://getbootstrap.com/docs/4.1/layout/overview/)
//  Example:
//      @include media-breakpoint-up(sm) { ... }

/**
 * Screen Size Mixins
 */
@mixin screen-size-xs {
    @media (max-width: #{$screen-size-sm - 1px}) {
        @content;
    }
}

@mixin screen-size-xs-up {
    @media (min-width: #{$screen-size-sm}) {
        @content;
    }
}

@mixin screen-size-sm {
    @media (max-width: #{$screen-size-md - 1px}) {
        @content;
    }
}

@mixin screen-size-sm-up {
    @media (min-width: #{$screen-size-md}) {
        @content;
    }
}

@mixin screen-size-md {
    @media (max-width: #{$screen-size-lg - 1px}) {
        @content;
    }
}

@mixin screen-size-md-up {
    @media (min-width: #{$screen-size-lg}) {
        @content;
    }
}

@mixin screen-size-lg {
    @media (max-width: #{$screen-size-xl - 1px}) {
        @content;
    }
}

@mixin screen-size-lg-up {
    @media (min-width: #{$screen-size-xl}) {
        @content;
    }
}

@mixin screen-size-xl {
    @media (min-width: #{$screen-size-xl}) {
        @content;
    }
}
