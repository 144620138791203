/**
 * DO NOT IMPORT THIS INTO MAIN.SCSS
 * The repo importing this package should manually import this as it's vendor specific.
 * This should be imported after the bootstrap SCSS is imported else it will be overridden by the default styling.
 */
@import '../../variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';

/***********************************************************************************************************************
 ** Buttons
 **********************************************************************************************************************/
.btn {
    line-height: rem-to-px($line-height-sm * 1rem);
    min-width: 100px;
}

.btn.btn-sm {
    --bs-btn-font-size: $line-height-sm;
    line-height: rem-to-px($line-height-xs * 1rem);
    min-width: initial;
}

.btn.btn-lg {
    max-width: 345px;
    width: 100%;
}

// Update background colours on hover to have a gradient
@each $color, $value in $theme-colors {
    $hover-background: $value linear-gradient(to bottom right, $value, lighten($value, 10%)) !important;

    .btn-#{$color} {
        border: transparent solid 1px !important; // NOTE this keeps the same element height and width between btn-x and btn-outline-x

        &:hover {
            background: $hover-background;
        }

        &:focus,
        &.focus {
            background: $hover-background;
        }
    }
}




/***********************************************************************************************************************
 ** Nav bar
 ** Add hover on dropdown menus for desktop
 ** https://bootstrap-menu.com/detail-basic-hover.html
 **********************************************************************************************************************/
@include media-breakpoint-up('lg') {
    .navbar .nav-item .dropdown-menu {
        margin-top: 0;
        display: none;
    }
    .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }
}
