/*!
 * _dashboard.scss
 * Little Phil
 *
 * Created on 5/8/22
 * Copyright © 2018 Little Phil. All rights reserved.
 */

@mixin container-classes() {
    @extend .pt-md-7;
    @extend .pt-6;
    @extend .pb-7;
    @extend .px-md-7;
}

.dashboard-container {
    @extend .container;
    @include container-classes();
}

.dashboard-container-fluid {
    @extend .container-fluid;
    @include container-classes();
}

.dashboard-card {
    // NOTE: do not set the background of this class as it may differ in certain places
    // NOTE: do not set the overflow to hidden due to dropdown menus
    border: 1px solid $reliable-blue-10;
    @extend .rounded-3;

    &:not(.p-0):not(.px-0):not(.py-0) {
        @extend .p-6;
    }

    &:not(.p-0):not(.px-0) {
        @extend .px-6;
    }

    &:not(.p-0):not(.py-0) {
        @extend .py-6;
    }

}
